.icon-button {
  align-items: center;
  appearance: none;
  background: Black;
  /* border-radius: 50%; */
  border-radius: 8px;
  border: solid 2px #666;
  color: White;
  display: flex;
  font-size: 20px;
  height: 40px;
  justify-content: center;
  outline: 0;
  padding: 0;
  width: 40px;
}

.icon-button:not(:disabled) {
  cursor: pointer;
}

.icon-button:disabled {
  background-color: #333;
  border-color: #666;
  color: #666;
}

.icon-button:not(:disabled):hover {
  border-color: #999;
}

.icon-button:not(:disabled):active {
  background: #666;
  border-color: #ccc;
}

.icon-button--warning {
  background-color: #f00;
  border-color: White;
}

.icon-button.icon-button--warning:not(:disabled):hover {
  background-color: #900;
  border-color: #f00;
}

.icon-button.icon-button--warning:not(:disabled):active {
  background-color: #600;
  border-color: #900;
}
