.video-box {
  border-radius: 5px;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.video-box .video-box__splash {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.video-box .video-box__splash.video-box__splash--no-video {
  background-color: Black;
}

/* .video-box .video-box__splash:not(.video-box__splash--no-video) {
  background-image: url(../images/tv-noise.gif);
  background-size: cover;
} */

.video-box .video-box__splash-icon {
  color: White;
  font-size: 80px;
}

.video-box .video-box__video-source {
  border-radius: 5px; /* Required by Safari */
  position: absolute;
  left: 0;
  top: 0;
}

.video-box .video-box__bottom-bar {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  height: 60px;
  justify-content: center;
  position: absolute;
  transition: bottom 150ms;
  width: 100%;
}

.video-box:not(:hover) .video-box__bottom-bar {
  bottom: -60px;
}

.video-box .video-box__bottom-bar-left {
  position: absolute;
  left: 10px;
  top: 10px;
}

.video-box .video-box__bottom-bar-center {
  /* column-gap: 10px; */
  display: flex;
  height: 100%;
  justify-content: center;
  left: 60px;
  padding: 10px 0;
  position: absolute;
  top: 0;
  width: calc(100% - 120px);
}

.video-box .video-box__bottom-bar-center > *:not(:first-child) {
  margin-left: 10px;
}

.video-box .video-box__bottom-bar-right {
  position: absolute;
  right: 10px;
  top: 10px;
}

.video-box .video-box__upper-left {
  display: flex;
  justify-content: flex-end;
  left: 0;
  margin: 4px;
  position: absolute;
  top: 0;
}

.video-box .video-box__upper-right {
  display: flex;
  justify-content: flex-end;
  margin: 4px;
  position: absolute;
  top: 0;
  right: 0;
}

.video-box .video-box__status_icon {
  margin-left: 4px;
}
