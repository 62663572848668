.status-icon {
  align-items: center;
  background-color: White;
  border-radius: 4px;
  color: Black;
  column-gap: 4px;
  display: flex;
  height: 24px;
  padding: 0 4px;
}

.status-icon--emphasis {
  background-color: #333;
  color: White;
}

.status-icon--warning {
  background-color: #c00;
  color: White;
}

.status-icon__icon {
  font-size: 16px;
  padding: 4px 0;
}

.status-icon__label {
  font-size: 12px;
  line-height: 12px;
}
