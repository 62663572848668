.guest-mode {
}

.guest-mode__form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.guest-mode__form > *:not(:first-child) {
  margin-top: 20px;
}

.guest-mode__invite-code-input {
  appearance: none;
  background-color: Black;
  border: solid 2px #666;
  color: White;
  font-family: Consolas, 'Courier New', Courier, monospace;
  font-size: 20px;
  outline: 0;
  padding: 10px;
  text-align: center;
}

.guest-mode__invite-code-input:hover {
  border: solid 2px #999;
}

.guest-mode__invite-code-input:focus {
  border: solid 2px #ccc;
}

.guest-mode__invite-code-input:disabled {
  background-color: #333;
  border: solid 2px #666;
  color: #666;
}

.guest-mode__leave-button {
  bottom: 20px;
  opacity: 0.5;
  position: absolute;
  right: 20px;
}
