.volume-control {
  /* background-color: yellow; */
  height: 160px;
  position: relative;
  touch-action: none;
  user-select: none;
  width: 40px;

  --bounding-box-size: 40px;
  --handle-size: 20px;

  --half-bounding-box-size: calc(var(--bounding-box-size) / 2);
  --half-handle-size: calc(var(--handle-size) / 2);
}

.volume-control__box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.volume-control__lower,
.volume-control__upper {
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  min-height: var(--half-handle-size);
  outline: 0;
}

.volume-control__upper {
  flex: 1 1;
}

.volume-control__lower {
  flex-shrink: 1;
}

.volume-control:active .volume-control__track {
  background-color: #333;
  border-color: #666;
}

.volume-control__track {
  background-color: #333;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  height: calc(100% - var(--handle-size));
  left: calc(50% - 3px);
  margin-top: var(--half-handle-size);
  position: absolute;
  top: 0;
  width: 6px;
}

.volume-control__track-level {
  background-color: #666;
  border-radius: 4px;
  height: 50%;
  width: 100%;
}

.volume-control__handle-box {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 0;
  justify-content: center;
  position: relative;
}

.volume-control__handle-bounding-box {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: var(--bounding-box-size);
  justify-content: center;
  position: absolute;
  top: calc(var(--half-bounding-box-size) * -1);
  width: var(--bounding-box-size);
}

.volume-control__handle {
  /* opacity: 0.5; */

  align-items: center;
  appearance: none;
  background: #333;
  border-radius: 100%;
  border: solid 2px #999;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  height: var(--handle-size);
  justify-content: center;
  outline: 0;
  padding: 0;
  width: var(--handle-size);
}

.volume-control:hover .volume-control__handle {
  border-color: #ccc;
}

.volume-control:active .volume-control__handle {
  background: #999;
  border-color: #fff;
}
