.side-bar {
  background-color: Black;
  overflow: hidden;
  transition: width 250ms;
  width: 80px;
}

.side-bar.side-bar--expanded {
  width: 360px;
}

.side-bar__box {
  display: flex;
  height: 100%;
  width: 360px;
}

.side-bar__main {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  padding: 10px;
  /* row-gap: 10px; */
  width: 60px;
}

.side-bar__main > *:not(:first-child) {
  margin-top: 10px;
}

.side-bar__rest {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  /* row-gap: 10px; */
}

.side-bar__rest > *:not(:first-child) {
  margin-top: 10px;
}

.side-bar__stay {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  /* row-gap: 10px; */
}

.side-bar__stay > *:not(:first-child) {
  margin-top: 10px;
}

.side-bar__aux {
  background-color: #111;
  color: #ccc;
  overflow-y: auto;
  padding: 10px;
  width: 260px;
}

.side-bar__peer {
  flex-shrink: 0;
}

.side-bar__divider {
  border: solid 1px #333;
  margin: 10px 0 0;
}
