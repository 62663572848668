.device-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.device-list .device-list__device {
  padding: .2em 0;
  user-select: none;
}

.device-list__device-label {
  margin-left: .2em;
}
