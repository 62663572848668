.peer-badge {
  background-color: #333;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 56px;
}

.peer-badge .peer-badge__label {
  text-align: center;
  background-color: #555;
  color: White;
  font-size: 11px;
  padding: 4px;
}

.peer-badge .peer-badge__buttons {
  display: flex;
  flex-direction: column;
  padding: 8px;
  /* row-gap: 10px; */
}

.peer-badge .peer-badge__buttons > *:not(:first-child) {
  margin-top: 10px;
}

.peer-badge__toggle-button {
  background-color: Black;
}
