.icon-toggle-button2 {
  /* border-radius: 50%; */
  border-radius: 8px;
  height: 40px;
  overflow: hidden;
  position: relative;
  width: 40px;
}

.icon-toggle-button2__input {
  appearance: none;
  border: 0; /* Required for Safari */
  height: 100%;
  outline: none;
  position: absolute;
  width: 100%;
}

.icon-toggle-button2__input:not(:disabled) {
  cursor: pointer;
}

.icon-toggle-button2__ring {
  border-style: solid;
  border-width: 2px;
  /* border-radius: 50%; */
  border-radius: 8px;
  font-size: 20px;
  height: 36px;
  width: 36px;
}

.icon-toggle-button2__body {
  align-items: center;
  /* border-radius: 50%; */
  border-radius: 8px;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: 2px;
  width: 32px;
}

.icon-toggle-button2__input:not(:checked) + * .icon-toggle-button2__body {
  background-color: Black;
  color: White;
}

.icon-toggle-button2__input:disabled:not(:checked) + * .icon-toggle-button2__body {
  background-color: Black;
  color: #999;
}

.icon-toggle-button2__input:not(:disabled):not(:checked):active + * .icon-toggle-button2__body {
  background-color: #666;
  color: White;
}

.icon-toggle-button2__input:checked + * .icon-toggle-button2__body {
  background-color: White;
  color: Black;
}

.icon-toggle-button2__input:disabled:checked + * .icon-toggle-button2__body {
  background-color: #999;
  color: Black;
}

.icon-toggle-button2__input:not(:disabled):checked:active + * .icon-toggle-button2__body {
  background-color: #ccc;
  color: Black;
}

.icon-toggle-button2__input:not(:checked) + .icon-toggle-button2__ring {
  border-color: #666;
}

.icon-toggle-button2__input:not(:disabled):not(:checked):hover + .icon-toggle-button2__ring {
  border-color: #999;
}

.icon-toggle-button2__input:not(:disabled):not(:checked):active + .icon-toggle-button2__ring {
  border-color: #ccc;
}

.icon-toggle-button2__input:checked + .icon-toggle-button2__ring {
  border-color: White;
}

.icon-toggle-button2__input:not(:disabled):checked:hover + .icon-toggle-button2__ring {
  border-color: #ccc;
}

.icon-toggle-button2__input:not(:disabled):checked:active + .icon-toggle-button2__ring {
  border-color: #999;
}

.icon-toggle-button2--warning .icon-toggle-button2__input:not(:checked) + * .icon-toggle-button2__body {
  background-color: Black;
  color: #f00;
}

.icon-toggle-button2--warning .icon-toggle-button2__input:not(:disabled):not(:checked):active + * .icon-toggle-button2__body {
  background-color: #600;
  color: #f00;
}

.icon-toggle-button2--warning .icon-toggle-button2__input:checked + * .icon-toggle-button2__body {
  background-color: #c00;
  color: White;
}

.icon-toggle-button2--warning .icon-toggle-button2__input:not(:disabled):checked:active + * .icon-toggle-button2__body {
  background-color: #c00;
  color: White;
}

.icon-toggle-button2--warning .icon-toggle-button2__input:not(:checked) + .icon-toggle-button2__ring {
  border-color: #600;
}

.icon-toggle-button2--warning .icon-toggle-button2__input:not(:disabled):not(:checked):hover + .icon-toggle-button2__ring {
  border-color: #900;
}

.icon-toggle-button2--warning .icon-toggle-button2__input:not(:disabled):not(:checked):active + .icon-toggle-button2__ring {
  border-color: #c00;
}

.icon-toggle-button2--warning .icon-toggle-button2__input:checked + .icon-toggle-button2__ring {
  border-color: #c00;
}

.icon-toggle-button2--warning .icon-toggle-button2__input:not(:disabled):checked:hover + .icon-toggle-button2__ring {
  border-color: #900;
}

.icon-toggle-button2--warning .icon-toggle-button2__input:not(:disabled):checked:active + .icon-toggle-button2__ring {
  border-color: #600;
}
