.selectable-device {
  position: relative;
}

.selectable-device__badge {
  align-items: center;
  background-color: #333;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
}

.selectable-device__icon-box {
  align-items: center;
  background-color: #444;
  color: #ccc;
  display: flex;
  justify-content: center;
  height: 50px;
  width: 50px;
}

.selectable-device__icon {
  font-size: 24px;
}

.selectable-device__label {
  flex: 1;
  font-size: 14px;
  margin-left: 10px;
}

.selectable-device {
  display: block;
  position: relative;
}

.selectable-device__input {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

.selectable-device__input:not(:disabled) + .selectable-device__badge {
  cursor: pointer;
}

.selectable-device__input:hover + * > .selectable-device__icon-box {
  background-color: #555;
}

.selectable-device__input:active + * > .selectable-device__icon-box {
  background-color: #999;
  color: Black;
}

.selectable-device__input:checked + * > .selectable-device__icon-box {
  background-color: #ddd;
  color: Black;
}

.selectable-device__input:hover.selectable-device__input:checked + * > .selectable-device__icon-box {
  background-color: #bbb;
}

.selectable-device__input:active.selectable-device__input:checked + * > .selectable-device__icon-box {
  background-color: Gray;
  color: White;
}
