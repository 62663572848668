.video-source {
  background-color: Black;
  height: 100%;
  position: relative;
  width: 100%;
}

.video-source__splash {
  align-items: center;
  background-image: url(../images/tv-noise.gif);
  background-size: cover;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.video-source__splash-icon {
  color: White;
  font-size: 80px;
}

.video-source__video-element {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
