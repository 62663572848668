.peer-video .peer-video__bottom-bar {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  height: 60px;
  justify-content: center;
  position: absolute;
  transition: bottom 150ms;
  width: 100%;
}

.peer-video:not(:hover) .peer-video__bottom-bar {
  bottom: -60px;
}

.peer-video .peer-video__bottom-bar-left {
  position: absolute;
  left: 10px;
  top: 10px;
}

.peer-video .peer-video__bottom-bar-center {
  /* column-gap: 10px; */
  display: flex;
  height: 100%;
  justify-content: center;
  left: 60px;
  padding: 10px 0;
  position: absolute;
  top: 0;
  width: calc(100% - 120px);
}

.peer-video .peer-video__bottom-bar-center > *:not(:first-child) {
  margin-left: 10px;
}

.peer-video .peer-video__bottom-bar-right {
  position: absolute;
  right: 10px;
  top: 10px;
}
