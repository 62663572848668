.big-button-screen {
  align-items: center;
  background-color: Black;
  color: #666;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.big-button-screen > *:not(:first-child) {
  margin-top: 10px;
}

.big-button-screen__button {
  font-size: 64px!important;
  height: 127px!important;
  width: 127px!important;
}
