.sign-in-screen__register-button {
  bottom: 20px;
  opacity: 0.5;
  position: absolute;
  right: 20px;
}

.sign-in-screen__guest-mode-button {
  bottom: 20px;
  opacity: 0.5;
  position: absolute;
  left: 20px;
}
