body,
html,
.app {
  height: 100%;
}

.app {
  background-color: Black;
  display: flex;
  height: 100%;
}

.app .app__video-grid {
  display: grid;
  flex: 1;
  grid-gap: 5px;
}

@media screen and (orientation: landscape) {
  .app .app__video-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    /* grid-template-columns: repeat(2, calc(50% - 2.5px));
    grid-template-rows: repeat(2, calc(50% - 2.5px)); */
  }
}

@media screen and (orientation: portrait) {
  .app .app__video-grid {
    grid-template-rows: repeat(4, 1fr);
    /* grid-template-rows: repeat(4, calc(25% - 2.5px)); */
  }
}

.app .app__video-grid__video {
  background-color: #111;
  overflow: hidden;
}

.app .app__side-bar {
  background-color: orange;
  flex: 1;
  overflow-y: auto;
  padding: 5px;
  scroll-behavior: smooth;
}

.app .app__video-grid__video-source {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

/* @media screen and (orientation: landscape) {
  .app .app__video-grid {
    height: 100vh;
    padding-right: 5px;
    width: 133.3vh;
  }
}

@media screen and (orientation: portrait) {
  .app {
    flex-direction: column;
  }

  .app .app__video-grid {
    height: 66.67vw;
    padding-bottom: 5px;
    width: 100vw;
  }
} */

.app .app__peer-state {
  column-gap: 1em;
  display: grid;
  font-size: 80%;
  grid-template-columns: auto 1fr;
  row-gap: 0.2em;
}

.app .app__peer-state-key {
  grid-column: 1;
}

.app .app__peer-state-value {
  grid-column: 2;
  margin: 0;
}
